import React, { useEffect, useRef, useState } from 'react';
import { FocusZone } from '@fluentui/react/lib/FocusZone';
import { List } from '@fluentui/react/lib/List';
import { IRectangle } from '@fluentui/react/lib/Utilities';
import { createListItems, IExampleItem } from '@fluentui/example-data';
import { ServiceDetailedInfo } from '../ServiceDetailedInfo';
import { useConst } from '@fluentui/react-hooks';
import itLogo from '../../Assets/it.png';
import prLogo from '../../Assets/PR.jpg';
import hrLogo from '../../Assets/HR.png';
import financeLogo from '../../Assets/finance.png';
import axios from "axios";
import { ITheme, getTheme, mergeStyleSets } from '@fluentui/react/lib/Styling';
import IService from '../../Models/Service';
import './GridItems.css';
import { ButtonRequestAcessOrSendError } from '../ButtonRequestAcessOrSendError';
import IFavourite from '../../Models/Favourite';
import { hover } from '@testing-library/user-event/dist/hover';
import { ModalWindowInfo } from '../ModalWindowInfo';
import { ButtonSendFeedback } from '../ButtonSendFeedback';
import { useBoolean } from '@fluentui/react-hooks';
import ServerInfo from '../../Models/ServerInfo';
import { ServerListButton } from '../ServerListButton';

import { ContextualMenu, ContextualMenuItemType, IContextualMenuItem, IContextualMenuProps } from '@fluentui/react/lib/ContextualMenu';
import { Button, DefaultButton } from '@fluentui/react/lib/Button';
import { useMsal } from "@azure/msal-react";
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { TextField } from '@fluentui/react/lib/TextField';
import styles from './ButtonRequestAcessOrSendError.module.css';
import IRatingFeedback from '../../Models/RatingFeedback';
import { AppService } from '../../Services/AppService';
import IServerInfo from '../../Models/ServerInfo';

const theme: ITheme = getTheme();
const { palette, fonts } = theme;
const ROWS_PER_PAGE = 10;
const MAX_ROW_HEIGHT = 290;
const classNames = mergeStyleSets({
    listGridExample: {
        overflow: 'hidden',
        fontSize: 0,
        position: 'relative',
        marginLeft: '0%',
        marginRight: '0%',
    },
    listGridExampleTile: {
        paddingLeft: '20px',
        marginBottom: '20px',
        marginRight: '10px',
        marginLeft: '10px',
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19)',
        textAlign: 'center',
        outline: 'none',
        position: 'relative',
        float: 'left',
        background: palette.white,
        selectors: {
            'focus:after': {
                content: '',
                position: 'absolute',
                left: 2,
                right: 2,
                top: 2,
                bottom: 2,
                boxSizing: 'border-box',
                border: `1px solid ${palette.white}`,
            },
        },
    },
    listGridExampleSizer: {
        paddingBottom: '270px',
        marginBottom: '20pxs'
    },
    listGridExamplePadder: {
        position: 'absolute',
        left: 2,
        top: 2,
        right: 2,
        bottom: 2,
    },
    listGridExampleLabel: {
        background: palette.themeLight,
        color: 'black',
        position: 'absolute',
        padding: 10,
        left: 0,
        width: '100%',
        fontSize: fonts.small.fontSize,
        boxSizing: 'border-box',
        textAlign: 'left',
    },
    listGridExampleLabel2: {
        color: 'black',
        position: 'absolute',
        padding: 10,
        left: 60,
        width: '60%',
        fontSize: fonts.small.fontSize,
        boxSizing: 'border-box',
        textAlign: 'left',
    },
    listGridExampleLabelIssueOpenedCount: {
        color: 'red',
        position: 'absolute',
        padding: 10,
        right: 15,
        width: '24%',
        fontSize: fonts.small.fontSize,
        boxSizing: 'border-box',
        textAlign: 'right',
    },
    listGridServersCount2: {
        color: 'black',
        position: 'absolute',
        padding: 4,
        paddingLeft:12,
        top: 36,
        left: 60,
        width: '30%',
        fontSize: fonts.small.fontSize,
        boxSizing: 'border-box',
        textAlign: 'left',
        background: palette.themeLight,
        
        borderBottomRightRadius: '40px'
    },
    listGridServersCount: {
        position: 'absolute',
        top: 210,
        right: 14,
        fontSize: fonts.medium.fontSize,
        boxSizing: 'border-box'
    },
    listGridExampleLabelAcessOrError: {
        color: 'black',
        position: 'absolute',
        paddingTop: 5,
        right: 5,
        width: '100%',
        fontSize: fonts.small.fontSize,
        boxSizing: 'border-box',
        textAlign: 'right',
    },
    listGridExampleName: {
        position: 'absolute',
        top: 70,
        left: 5,
        height: '45px',
        fontSize: '15px',
        padding: '5px',
        boxSizing: 'border-box',
        textAlign: 'left',
        /*   textOverflow: 'ellipsis',*/
        display: 'block',
        /*      whiteSpace: 'nowrap',*/
        overflow: 'hidden',
        color: 'black',
        selectors: {
            ':hover': {
                textDecoration: 'underline'
            }
        }
    },
    listGridExampleNameNoUrl: {
        position: 'absolute',
        top: 70,
        left: 5,
        height: '45px',
        fontSize: '15px',
        padding: '5px',
        boxSizing: 'border-box',
        textAlign: 'left',
        /*  textOverflow: 'ellipsis',*/
        display: 'block',
        color: '#A0A0A0',
        /*   whiteSpace: 'nowrap',*/
        overflow: 'hidden',
        cursor: 'not-allowed',
    },
    listGridExampleDescription: {
        position: 'absolute',
        top: 125,
        left: 5,

        width: '96%',
        fontSize: fonts.small.fontSize,
        boxSizing: 'border-box',
        textAlign: 'justify',
        paddingLeft: '5px',
        paddingRight: '5px',
        color: 'black'
    },
    listGridExampleSystemIcon: {
        background: 'white',
        position: 'absolute',
        top: 10,
        left: 10,
        width: '50px',
        height: '50px',
        fontSize: '12px',
        boxSizing: 'border-box',
        border: `1px solid ${palette.neutralTertiary}`,
        zIndex: 1,
    },
    listGridExampleSystemIconText: {
        position: 'absolute',
        top: 10,
        left: 15,
        fontSize: fonts.small.fontSize,
        boxSizing: 'border-box',
    },
    listGridExampleInfoBlock: {
        //background: '#f5f5f5',
        //position: 'absolute',
        //top: 210,
        //right: 5,
        //width: '50%',
        //fontSize: fonts.large.fontSize,
        //boxSizing: 'border-box'


        //background: '#f5f5f5',
        //color: 'black',
        //position: 'absolute',
        //padding: 10,
        //left: 0,
        //bottom: 0,
        //height:40,
        //width: '100%',
        //fontSize: fonts.small.fontSize,
        //boxSizing: 'border-box',
        //textAlign: 'left',
    },
    listGridExampleFavorite: {
        position: 'absolute',
        top: 230,
        left: 10,
        fontSize: fonts.large.fontSize,
        boxSizing: 'border-box'
    },

    listGridExampleInfo: {
        position: 'absolute',
        top: 230,
        right: 10,
        fontSize: fonts.large.fontSize,
        boxSizing: 'border-box'
    },
    listGridExampleStatusOperational: {
        background: '#e6ffe6',
        color: 'black',
        position: 'absolute',
        top: 245,
        borderRadius: '25px',
        right: '25%',
        width: '50%',
        fontSize: fonts.small.fontSize,
        boxSizing: 'border-box',
        textAlign: 'center',
    },
    listGridExampleStatusPlanned: {
        background: ' #ffffcc',
        color: 'black',
        position: 'absolute',
        top: 245,
        borderRadius: '25px',
        right: '25%',
        width: '50%',
        fontSize: fonts.small.fontSize,
        boxSizing: 'border-box',
        textAlign: 'center',
    },
    listGridExampleStatusRetracted: {
        background: '#ffd6cc',
        color: 'black',
        position: 'absolute',
        top: 245,
        borderRadius: '25px',
        right: '25%',
        width: '50%',
        fontSize: fonts.small.fontSize,
        boxSizing: 'border-box',
        textAlign: 'center',
    },
    listGridExampleAvaiable: {
        background: palette.themeLight,
        color: 'black',
        position: 'absolute',
        top: 35,
        borderBottomLeftRadius: '25px',
        right: '0',
        width: '60%',
        height: '30px',
        fontSize: fonts.small.fontSize,
        boxSizing: 'border-box',
        textAlign: 'center',
    },
    listGridExampleGMP: {
        background: "#EDEDED",
        color: 'black',
        position: 'absolute',
        top: 36,
        right: 0,
        paddingTop: 10,
        paddingBottom: 10,
        paddingRight: 5,
        width: '25%',
        borderBottomLeftRadius: '35px',
        fontSize: fonts.small.fontSize,
        boxSizing: 'border-box',
        textAlign: 'right',
    },
    listGridExampleHosting: {
        background: "#EDEDED",
        color: 'black',
        position: 'absolute',
        top: 220,
        borderRadius: '25px',
        right: '25%',
        width: '50%',
        fontSize: fonts.small.fontSize,
        boxSizing: 'border-box',
        textAlign: 'center',
    },

    listGridExampleServerList: {
        position: 'absolute',
        top: 195,
        right: 10,
        fontSize: fonts.large.fontSize,
        boxSizing: 'border-box'
    },
    
});

type Service = {
    description: string;
    name: number;
    status: string;
}


export const GridItems: React.FunctionComponent<{
    data: IService[] | undefined,
    onFavouriteClick: any,
    onAddFavourite: any,
    onRemoveFavourite: any,
    onClickTitle: any,
    onServerOpen:(service: IService | undefined) => Promise<ServerInfo[] | undefined>,
}> = ({ children, data, onAddFavourite, onRemoveFavourite, onFavouriteClick, onClickTitle,onServerOpen }) => {

    const [width, setWidth] = useState<number>(window.innerWidth);
    const [serversInfo, setServersInfo] = React.useState<ServerInfo[] >();
    const [itemCode, setItemCode] = React.useState<string>();
    const [itemApplicationName, setitemApplicationName] = React.useState<string>();
    /* const [dataFromDB, setdataFromDB] = useState<IService[]>();*/
    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    function truncate(source: any, size: any) {
        if (source !== undefined && size !== undefined) {
            return source.length > size ? source.slice(0, size - 1) + "…" : source;
        }
    }



    const manageFavourite = (service: IService | undefined) => {
        if (service?.isFavourite) {
            onRemoveFavourite(service);
        } else {
            if (service !== undefined) {

                onAddFavourite(service);
            }
        }
    }
    const manageListServers = (service: IService | undefined) => {
        if(service)
        {
            setitemApplicationName(service?.name);
            console.log(service);
            setLoadingData(true);
            appService.getServerInfo(service.jiraId).then((servers) => {
            setServerData(servers.data);
            setLoadingData(false);
            }).catch((error) => {
            console.error("Error fetching server information:", error);
            setLoadingData(false);
            });
        }
    }
    const itemCodeRef = useRef<string | undefined>();
    const loadDataToServerList = (service: IService | undefined) => {
        setItemCode(service?.code);
        setitemApplicationName(service?.name);
    }

    function getStatusColor(status: string | undefined) {
        let colorClass;
        switch (status) {
            case "Operacyjna":
                colorClass = classNames.listGridExampleStatusOperational;
                break;
            case "Planowana":
                colorClass = classNames.listGridExampleStatusPlanned;
                // code block planned
                break;
            case "Wygaszana":
                colorClass = classNames.listGridExampleStatusRetracted;
                break;
        }
        return colorClass;
    }

    useEffect(() => {
        itemCodeRef.current = itemCode;
        /*       setdataFromDB(data);*/
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    },  [itemCode]);

    const isMobile = width <= 525;

    const columnCount = React.useRef(0);
    const rowHeight = React.useRef(0);

    const getItemCountForPage = React.useCallback((itemIndex: number | undefined, surfaceRect: IRectangle | undefined) => {
        if (itemIndex === 0 && surfaceRect != null) {
            if (surfaceRect.width > 2000) {
                columnCount.current = Math.round(2000 / MAX_ROW_HEIGHT);
                rowHeight.current = Math.floor(2000 / columnCount.current);
            } else {
                columnCount.current = Math.round(surfaceRect.width / MAX_ROW_HEIGHT);
                rowHeight.current = Math.floor(surfaceRect.width / columnCount.current);
            }
          
        }
        return columnCount.current * ROWS_PER_PAGE;
    }, []);


    function getImageForItem(code: string | undefined) {
        let iconClass;
        switch (code) {
            case 'API':
                iconClass = 'bi bi-asterisk'
                break;
            case 'Business Development and R&D':
                iconClass = 'bi bi-briefcase-fill'
                break;
            case 'Finance':
                iconClass = 'bi bi-bank2'
                break;
            case 'HR':
                iconClass = 'bi bi-people-fill'
                break;
            case 'Industrial Operations':
                iconClass = 'bi bi-boxes'
                break;
            case 'Procurement':
                iconClass = 'bi bi-graph-up-arrow'
                break;
            case 'Quality':
                iconClass = 'bi bi-gem'
                break;
            case 'Group Commercial & Marketing':
                iconClass = 'bi bi-arrow-repeat'
                break;
            case 'Corporate Development':
                iconClass = 'bi bi-globe2'
                break;
            case 'Legal':
                iconClass = 'bi bi-journal-check'
                break;
            case 'PR':
                iconClass = 'bi bi-bullseye'
                break;
            case 'Administration':
                iconClass = 'bi bi-geo-alt-fill'
                break;
            case 'IT':
                iconClass = 'bi bi-laptop'
                break;
            case 'Security':
                iconClass = 'bi bi-shield-shaded'
                break;
            default:
            // code block
        }
        return iconClass;
    }



    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [serverData, setServerData] = React.useState<IServerInfo[]>();
    const [loadingData, setLoadingData] = React.useState<boolean>(true);
    const appService = new AppService();
    const clearDataOfServer = () =>{
        console.log('clear server info');
        toggleHideDialog();
        setitemApplicationName('');
        setServerData([]);
       
    }
    const handleItemClick1 = (extraParam1: any,extraParam2: any) => (ev?: any, item?: IContextualMenuItem) => {
        if (item?.key === 'ServerList') {
            if (extraParam1) {
              setitemApplicationName(extraParam2);
              setLoadingData(true);
              appService.getServerInfo(extraParam1).then((servers) => {
                setServerData(servers.data);
                setLoadingData(false);
              }).catch((error) => {
                console.error("Error fetching server information:", error);
                setLoadingData(false);
              });
            }
            toggleHideDialog();
          }
      };

      const modelProps = {
        isBlocking: false,
        styles: { main: { maxWidth: 650 } },
    };

    const { instance } = useMsal();

    const accounts = instance.getAllAccounts();
    let activeAccount = null;
    let roles = null;
    let isBusinessAdmin = false; 
    if (accounts && accounts.length === 1) {
        activeAccount = accounts[0].name?.split(" ")[1];
        roles = accounts[0].idTokenClaims?.roles?.toString(); 
        if(roles?.includes('ItManager'))
        {
            isBusinessAdmin = true;
        }
    } else if (accounts && accounts.length > 1) {
        console.log('ERROR: Multiple users logged in');
    }

    const dialogContentProps = {
        type: DialogType.largeHeader,
        title: 'Polpharma IT',
        subText: 'Aplikacja '+ itemApplicationName,
    };

    const buildMenu = (service: IService | undefined) => {
        let listOfItems: IContextualMenuItem[] = [
            {
                key: 'AddPermission',
                name: 'Zawnioskuj o dostęp',
                target: '_blank',
                href: 'https://idm.polpharma.net/',
            },
            {
                key: 'SendError',
                name: 'Zgłoś problem',
                target: '_blank',
                href: 'https://servicedesk.polpharma.net/',
            },
        ];
    
        if (isBusinessAdmin) {
            const customItem: IContextualMenuItem = {
                key: 'ServerList',
                name: 'Lista serwerów',
                onClick: handleItemClick1(service?.jiraId, service?.name), 
            };
            listOfItems.push(customItem);
        }
    
        const menuProps: IContextualMenuProps = {
            shouldFocusOnMount: true,
            items: listOfItems,
        }
        return menuProps; // Add this line to return menuProps
    }

    const onRenderCell = React.useCallback((item: IService | undefined, index: number | undefined) => {

        return (
            <>
            <div
                className={classNames.listGridExampleTile}
                data-is-focusable
                style={{
                    width: (100 / columnCount.current) - 5 + '%',
                }}
            >
                <div className={classNames.listGridExampleSizer}>
                    <div className={classNames.listGridExamplePadder}>
                        <div className={classNames.listGridExampleSystemIcon}>
                            <i className={getImageForItem(item?.bpArea)}
                                style={{ fontSize: 35, color: '#404040' }}>
                            </i>
                        </div>
                        <span className={classNames.listGridExampleLabel}>{`IT`}</span>
                        <div className={classNames.listGridExampleLabelAcessOrError}> 
                        <DefaultButton  onClick={event => manageListServers(item)}
                        styles={{ root: { all: 'initial', width:'0px' } }}
                        text="" menuProps={buildMenu(item)} menuIconProps={{ iconName: "" }}>
                              <i className="bi bi-three-dots-vertical" style={{ fontSize: 20 }}></i>
                                
                        </DefaultButton>
                                              
                        </div>
                            <span className={classNames.listGridExampleLabel2}>{item?.code}</span>
                            <span className={`${item?.openedIssuesCount && isBusinessAdmin ? classNames.listGridExampleLabelIssueOpenedCount : ""}`}>{item?.openedIssuesCount} <i className="bi bi-unlock-fill"></i></span>

                            <span className={classNames.listGridServersCount2} title="Ilość serwerów przypisanych do systemu">{item?.serversCount} <i className="bi bi-hdd-fill"></i></span>
                            
                            <span className={`${item?.gmp ? classNames.listGridExampleGMP : ""}`}>GMP</span>
                        <a href={item?.url} target="_blank" style={{ textDecoration: 'none', color: 'inherit' }} onClick={event => onClickTitle(item)}>
                            <div className={item?.url !== null ? classNames.listGridExampleName : classNames.listGridExampleNameNoUrl} title={item?.name.toUpperCase()}>{truncate(item?.name.toUpperCase(), 100)}</div>
                        </a>
                        <span className={classNames.listGridExampleDescription} title={item?.description}>{truncate(item?.description, 100)}</span>
                        <div className={classNames.listGridExampleInfoBlock}></div>
                        <div className={classNames.listGridExampleFavorite} onClick={event => manageFavourite(item)}>
                            <i onClick={onFavouriteClick} title={item?.isFavourite ? 'Usuń z ulubionych' : 'Dodaj do ulubionych'} className={item?.isFavourite ? 'bi bi-star-fill' : 'bi bi-star'} style={{ fontSize: 25, color: 'black', cursor: 'pointer' }}></i>
                        </div>
                        <span className={classNames.listGridExampleHosting}>{item?.hosting} </span>     
                            <span className={`${getStatusColor(item?.status)}`}>{item?.status === 'Operacyjna' ? 'Aktywna' : item?.status}</span>
                        {/*    <span className={classNames.listGridServersCount}> {item?.serversCount} <i className="bi bi-hdd-fill"></i></span>*/}
                        <div className={classNames.listGridExampleInfo}>
                            <ServiceDetailedInfo
                                serviceName={item?.name}
                                apqcArea={item?.apqcArea}
                                bpArea={item?.bpArea}
                                businessOwner={item?.businessOwner}
                                businessAdministrator={item?.businessAdministrator}
                                managerIT={item?.managerIT}
                                supportTime={item?.supportTime}>
                            </ServiceDetailedInfo>
                        </div>
                    </div>
                </div>
            </div>
            </>
        );

    }, []);

    const getPageHeight = React.useCallback((): number => {
        return rowHeight.current * ROWS_PER_PAGE;
    }, []);
    return (
        <>
        <FocusZone>
            <List
                className={classNames.listGridExample}
                items={data}
                getItemCountForPage={getItemCountForPage}
                getPageHeight={getPageHeight}
                renderedWindowsAhead={20}
                onRenderCell={onRenderCell}
            />
        </FocusZone>

        <Dialog
                hidden={hideDialog}
                onDismiss={toggleHideDialog}
                dialogContentProps={dialogContentProps}
                modalProps={modelProps}
                containerClassName={'ms-dialogMainOverride ' + styles.textDialogNew}
            >
                <div className="star-rating">
                {loadingData && 
                <div> 
                    <div className="loading-list-servers spinner-border text-primary" role="status" style={{width: '15px',height: '15px'}}></div> Trwa ładowanie listy serwerów ...
                </div>}
                <div style={{marginBottom:'20px'}}>W celu wprowadzenia zmian na poniższej liście (dodanie, usunięcie lub modyfikacja) proszę skorzystać z ofert <a href="https://polpharma.atlassian.net/servicedesk/customer/portal/11" target="_blank">JIRA Centrum pomocy</a> </div>

                <ul>
                    {serverData && serverData?.length > 0 ? (
                       <div>
                       
                                       <table>
                                       <thead>
                                           <tr>
                                            <th style={{ paddingLeft: '5px', paddingRight: '10px',paddingBottom: '5px' }}>Server Name</th>
                                            {window.innerWidth > 650 ? <th style={{ paddingLeft: '5px', paddingRight: '10px', paddingBottom: '5px' }}>Cost Optimization</th> : <span></span>}
                                            {window.innerWidth > 650 ? <th style={{ paddingLeft: '5px', paddingRight: '10px', paddingBottom: '5px' }}>Availability</th> : <span></span>}
                                            <th style={{ paddingLeft: '5px', paddingRight: '10px', paddingBottom: '5px' }}>Environment</th> 
                                            <th style={{ paddingLeft: '5px', paddingRight: '10px', paddingBottom: '5px' }}>Hosting</th>
                                            {window.innerWidth > 650 ? <th style={{ paddingLeft: '5px', paddingRight: '10px', paddingBottom: '5px' }}>CPU</th> : <span></span>}
                                            {window.innerWidth > 650 ? <th style={{ paddingLeft: '5px', paddingRight: '10px', paddingBottom: '5px' }}>Memory</th> : <span></span>}
                                            {window.innerWidth > 650 ? <th style={{ paddingLeft: '5px', paddingRight: '10px', paddingBottom: '5px' }}>Status</th> : <span></span>}
                                           </tr> 
                                       </thead>
                                       <tbody>
                                           {serverData && serverData?.length > 0 ? (
                                           serverData.map((server, index) => (
                                               <tr key={index} >
                                                   <td style={{ paddingLeft: '5px', paddingRight: '10px', paddingBottom: '5px' }}>{server.name}</td>
                                                   {window.innerWidth > 650 ? <td style={{ paddingLeft: '5px', paddingRight: '10px', paddingBottom: '5px' }}>{server.costOptimization}</td> : <span></span>}
                                                   {window.innerWidth > 650 ? <td style={{ paddingLeft: '5px', paddingRight: '10px', paddingBottom: '5px' }}>{server.availability}</td> : <span></span>}
                                                   <td style={{ paddingLeft: '5px', paddingRight: '10px',paddingBottom: '5px' }}>{server.environment}</td>
                                                   <td style={{ paddingLeft: '5px', paddingRight: '10px', paddingBottom: '5px' }}>{server.hosting}</td>
                                                   {window.innerWidth > 650 ? <td style={{ paddingLeft: '5px', paddingRight: '10px', paddingBottom: '5px' }}>{server.cpu}</td> : <span></span>}
                                                   {window.innerWidth > 650 ? <td style={{ paddingLeft: '5px', paddingRight: '10px', paddingBottom: '5px' }}>{server.memory}</td> : <span></span>}
                                                   {window.innerWidth > 650 ? <td style={{ paddingLeft: '5px', paddingRight: '10px', paddingBottom: '5px' }}>{server.status}</td> : <span></span>}

                                               </tr>
                                           ))
                                           ) : !loadingData ? (
                                           <tr>
                                               <td>Brak informacji</td>
                                           </tr>
                                           ) : null}
                                       </tbody>
                                       </table> 
                                       </div>
                        ): (!loadingData && <span>Brak informacji</span>)
                }
                </ul>
                </div>
                <div style={{ marginTop: '30px' }}>

                    </div>
                <DialogFooter>
                    <DefaultButton onClick={clearDataOfServer} text="Zamknij" />
                </DialogFooter>
            </Dialog>
        </>
    );
};
